import css from './Slider.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { createContext, FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Container } from '@core';
import { findLastIndex, normalize, padForNum, splitByLines } from '@utils';
import { HomeChildProps, HomeIntroContext } from '@components/HomeIntro/HomeIntro';
import { MediaBg } from '@components/HomeIntro/components/MediaBg/MediaBg';
import { getStages } from '@components/HomeIntro/utils';
import { ViewportContext } from '@context';

export interface HomeIntroSlide {
	gt?: boolean;
	odometer?: number;
	text?: string;
}

export interface SliderInterface {
	items: Array<HomeIntroSlide>;
	ticker?: string;
	picture?: Picture;
	video?: Video;
}

export interface SliderProps extends HomeChildProps {
	slider: SliderInterface;
}

export const SliderContext = createContext({ seek: 0, current: -1 });
export const TickerContext = createContext(0);

export const Slider: FC<SliderProps> = React.memo(
	({
		screens: { full: screens, before = 0 },
		start,
		end,
		slider: { items, ticker, picture, video },
	}) => {
		const { vw } = useContext(ViewportContext);

		const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

		const { seek: seekMain } = useContext(HomeIntroContext);

		const seek = normalize(seekMain, end, start, true);
		const stages = getStages(seek, screens);

		const sliderSeek = normalize(seek, 0.9, before / screens, true);
		const tickerTextSeek = normalize(seek, 0.9, before / screens, true);

		useEffect(() => {
			fastdom.mutate(() => {
				if (wrap) {
					// Через clip-path на родителе
					// wrap.style.clipPath = `polygon(${50 - stages[0] * 50}% 0, ${50 + stages[0] * 50}% 0, ${
					// 	50 + stages[0] * 50
					// }% 100%, ${50 - stages[0] * 50}% 100%)`;

					// Через ширину роидетля и object-fit: cover на видео
					wrap.style.width = stages[0] === 1 ? '100%' : `${(vw * stages[0]).toFixed(0)}px`;
				}
			});
		}, [vw, wrap, stages]);

		const current = items ? Math.min(
			findLastIndex(stages.slice(before, -1), (value) => value >= 1),
			items.length - 1
		) : 0;

		return (
			<SliderContext.Provider value={{ seek: sliderSeek, current }}>
				<TickerContext.Provider value={tickerTextSeek}>
					<Box ref={setWrap} className={classnames(css.module)}>
						<MediaBg picture={picture} video={video} playing={seek > 0 && seekMain < 1.2} />
						{items && <SliderContent items={items} />}
						<SliderTicker ticker={ticker} />
					</Box>
				</TickerContext.Provider>
			</SliderContext.Provider>
		);
	}
);

export const SliderContent: FC<Pick<SliderInterface, 'items'> & { showSymbols?: boolean }> =
	React.memo(({ items, showSymbols = true }) => {
		const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

		const [texts, setTexts] = useState<Array<string[]>>([]);
		const [textHelper, setTextHelper] = useState<HTMLDivElement | null>(null);

		const { seek, current } = useContext(SliderContext);

		useEffect(() => {
			if (!textHelper) return;

			const resizeObserver = new ResizeObserver(() => {
				fastdom.measure(() => {
					const texts = items.map((slide) => splitByLines(slide?.text || '', textHelper));
					setTexts(texts);
				});
			});

			resizeObserver.observe(textHelper);

			return () => {
				resizeObserver.unobserve(textHelper);
				resizeObserver.disconnect();
			};
		}, [textHelper, items]);

		const size = useMemo(() => items.length, [items]);
		const stages = getStages(seek, items.length * 2);
		const comb = useMemo(
			() =>
				stages.reduce((acc, stage, i) => {
					const ev = (i + 1) % 2 === 0;
					acc += ev ? stage : 0;
					return acc;
				}, 0),
			[stages]
		);

		useEffect(() => {
			fastdom.mutate(() => {
				if (!wrap) return;

				wrap.style.setProperty('--symbolsX', `${(comb - (size + 1)) * 100}%`);
				wrap.style.setProperty('--odometerA', `${comb * -100}%`);
				wrap.style.setProperty('--odometerB', `${comb * 1.3 * -100}%`);
				wrap.style.setProperty('--odometerC', `${comb * 1.8 * -100}%`);
				wrap.style.setProperty('--odometerD', `${comb * 2.3 * -100}%`);
			});
		}, [wrap, comb, size]);

		return (
			<Box ref={setWrap} className={classnames({ [css.isActive]: current >= 0 })}>
				<Container>
					{showSymbols && <SliderSymbols items={items} />}
					<Box className={css.layout}>
						<Box className={css.left}>
							<SliderStats items={items} />
						</Box>
						<Box className={css.right}>
							<Box className={css.text}>
								<Box ref={setTextHelper} className={css.textHelper} />
								<SliderTexts texts={texts} />
							</Box>
							<SliderCounter items={items} />
						</Box>
					</Box>
				</Container>
			</Box>
		);
	});

export const SliderStats: FC<Pick<SliderInterface, 'items'>> = React.memo(({ items }) => {
	const columns = useMemo(
		() =>
			items.reduce(
				(acc, item) => {
					if (item?.odometer) {
						padForNum(item.odometer, '----')
							.split('')
							.forEach((d, i) => {
								acc[i].push(d === '-' ? null : Number(d));
							});
					}
					return acc;
				},
				[[], [], [], []] as Array<Array<number | null>>
			),
		[items]
	);

	return (
		<Box className={css.odometerWrap}>
			<Box className={css.odometer}>
				{columns.map((column, i) => {
					return (
						<Box key={`slide-odometer-column-${i}`} className={css.odometerC}>
							<Box>&nbsp;</Box>
							{column.map((digit, j) => {
								return (
									<Box
										key={`slide-odometer-d-${j}`}
										className={classnames(css.odometerD, { [css.odometerAccent]: digit !== null })}>
										{digit !== null ? digit : 0}
									</Box>
								);
							})}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
});

export const SliderSymbols: FC<Pick<SliderInterface, 'items'>> = React.memo(({ items }) => {
	return (
		<Box className={css.symbol}>
			<Box className={css.symbolTrack}>
				{items.map((item, i) => (
					<div key={`slide-symbols-${i}`} className={css.symbolIcon}>
						{item?.gt && (
							<svg viewBox="0 0 168 174">
								<path d="M167.455 74.9161L0.216797 0.914062V32.8621L130.291 87.6301L0.216797 141.42V173.042L167.455 100.344V74.9161Z" />
							</svg>
						)}
					</div>
				))}
				<div className={css.symbolIcon} />
			</Box>
		</Box>
	);
});

export const SliderTexts: FC<{ texts: Array<string[]> }> = React.memo(({ texts }) => {
	const textGroupsByLines = useMemo(() => {
		const result = [];

		const maxLines = texts.reduce((acc, current, i) => {
			if (acc < current.length) {
				acc = current.length;
			}
			return acc;
		}, 0);

		for (let i = 0; i < maxLines; i++) {
			result.push(texts.map((text) => text[i] || ''));
		}

		return result;
	}, [texts]);

	return (
		<>
			{textGroupsByLines.map((line, i) => {
				return (
					<Box key={`group-${i}`} className={css.textLine}>
						<Box className={css.textTrack}>
							<div>&nbsp;</div>
							{line.map((item, j) => (
								<div key={`group-${i}-text-${j}-${item}`}>{item.length ? item : <>&nbsp;</>}</div>
							))}
						</Box>
					</Box>
				);
			})}
		</>
	);
});

export const SliderCounter: FC<Pick<SliderInterface, 'items'>> = React.memo(({ items }) => {
	return (
		<Box className={css.counter}>
			<div>0</div>
			<Box className={css.counterTrack}>
				<Box>&nbsp;</Box>
				{items.map((_, i) => (
					<Box key={`slide-counter-${i}`}>{i + 1}</Box>
				))}
			</Box>
			<span>&mdash;</span>
			<span>{items.length}</span>
		</Box>
	);
});

export const SliderTicker: FC<Pick<SliderInterface, 'ticker'>> = React.memo(({ ticker }) => {
	const [track, setTrack] = useState<HTMLDivElement | null>();

	const seek = useContext(TickerContext);

	useEffect(() => {
		if (track) {
			const width = track?.offsetWidth || 0;
			track.style.transform = `translate3d(${-width * seek}px, 0, 0)`;
		}
	}, [track, seek]);

	return (
		<Box className={css.ticker}>
			<Container>
				<Box className={css.tickerWrap}>
					<Box className={css.tickerTrack} ref={setTrack}>
						{ticker}
					</Box>
				</Box>
			</Container>
		</Box>
	);
});
